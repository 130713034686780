<template>
  <div id="news-component-css">
    <div class="container">
      <BaseBread>
        <BaseBreadItem to="/">首页</BaseBreadItem>
        <BaseBreadItem>新闻资讯</BaseBreadItem>
      </BaseBread>
          <BaseOneImage :dataSource="dataSource"/>
<!--      <div id="news-introduce" v-if="newsList">-->
<!--        <el-carousel indicator-position="outside">-->
<!--          <el-carousel-item v-for="item in 4" :key="item">-->
<!--            <h3 text="2xl" justify="center">{{ item }}</h3>-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
<!--      </div>-->
      <div class="product-list">
        <!-- 左：侧边栏组件 -->
        <!--      <BaseLeftMenu :menuList="menuList"/>-->
        <!-- 右：内容 -->
        <div class="article">
          <!-- 三级路由的出口 -->
          <div v-if="!id">
            <div class="order-list">
              <div v-if="loading" class="loading"></div>
              <div class="none" v-if="!loading && newsList.length === 0">

                <div class="cart-none">
                  <img src="@/assets/images/none.png" alt="加载失败"/>
                  <p>暂无数据</p>

                </div>

              </div>
              <NewsList :news="newsList"/>
            </div>
            <!-- 分页组件 -->
            <BasePagination
              v-if="total>0"
              :current-page="reqParams.pageIndex"
              :page-size="reqParams.pageSize"
              :total="total"
              @current-change="changePagerFn"
            />
          </div>
          <div v-else>
            <RouterView/>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'swiper/swiper.css'
import 'swiper/modules/pagination.css'
import 'swiper/modules/navigation.css'


import {useRoute} from "vue-router";
import {ref, reactive, watch} from "vue";
import NewsList from './components/news-list'
import {findNewsCategory, findNewsByCategoryId} from '@/api/news'
import BaseOneImage from "@/components/library/base-one-image.vue";
import {findAd} from "@/api/home";
import {Parallax, Pagination, Navigation} from 'swiper/modules'
import {Swiper, SwiperSlide} from 'swiper/swiper-vue.mjs'
import BaseBread from "@/components/library/base-bread.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";
import BasePagination from "@/components/library/base-pagination.vue";

export default {
  name: 'NewsName',
  props: {
    news: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    BasePagination,
    BaseBreadItem,
    BaseBread,
    BaseOneImage,
    NewsList,
    Swiper, SwiperSlide
  },
  setup(props) {
    const dataSource = ref([])
    findAd("newsTopImg").then(data => {
      dataSource.value = data.data.list[0]
    })

// 获取当前路由对象
    const route = useRoute();
// 获取动态路由参数的name
    const id = ref()

    const loading = ref(false)
    const total = ref(0)
    const newsList = ref([]);
// 筛选条件
    const newsALittleList = ref([]);
    const reqParams = reactive({
      pageIndex: 1,
      pageSize: 5,
    })

    const menuList = ref([]);
    // 查找新闻种类
    // findNewsCategory().then(data => {
    //   menuList.value = data.data.list
    // })


    watch(() => route.params.id, (newVal) => {
      reqParams.categoryId = route.params.id
      findNewsByCategoryId(reqParams).then(data => {
        loading.value = true
        newsList.value = data.data.records
        if (newsList) {
            for (let i = 0; i < 4; i++) {
              if (i===newsList.value.length){
                break;
              }
              newsALittleList.value[i] = newsList.value[i]
          }
        }
        total.value = data.data.total
        loading.value = false
      })
    }, {immediate: true})

    // 实现分页切换
    const changePagerFn = (newPage) => {
      reqParams.pageIndex = newPage
      findNewsByCategoryId(reqParams).then(data => {
        loading.value = true
        newsList.value = data.data.records
        total.value = data.data.total
        loading.value = false
      })
    }
    return {
      menuList, newsList, id, total, reqParams, loading, changePagerFn, dataSource, newsALittleList,
      modules: [Parallax, Pagination, Navigation]
    }
  }
}

</script>

<style scoped lang="less">
#news-component-css {
  .product-list {
    display: flex;

    .article {
      width: 100%;
    }
  }

  .cart-none {
    text-align: center;
    padding: 150px 0;
    background: #fff;

    img {
      width: 180px;
    }

    p {
      color: #999999;
      padding: 20px 0;
    }
  }


  #news-introduce {
    .el-carousel__item h3 {
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
      text-align: center;
    }

    .el-carousel__item:nth-child(2n) {
    }

    .el-carousel__item:nth-child(2n + 1) {
    }

    img{
      width: 670px;
      height: 500px;
    }


    .title {
      margin-top: 0;
      margin-bottom: 10px; // 假定@lg-gap已经在Less中定义
    }

    .subtitle {
      margin-top: 0;
      margin-bottom: 10px; // 假定@gap已经在Less中定义
    }

    .text {
      max-width: 430px;
      line-height: 1.32;
    }
  }
}
</style>
